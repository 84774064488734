
import Vue, { PropType } from 'vue';
import { AtomType } from '@/domains/atom';
import TTStatusIcon from '../../../../vendors/titify/src/components/ui/TTStatusIcon.vue';

export default Vue.extend({
  name: 'MaterialTypeIcon',
  components: {
    TTStatusIcon,
  },
  props: {
    atomType: {
      type: String as PropType<AtomType>,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: 'tt-light-blue',
    },
    iconColor: {
      type: String,
      default: 'tt-light-blue',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isCompleted: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    isFailed: {
      type: Boolean,
      default: false,
    },
    isWasted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon(): string {
      // Иконки МОГУТ совпадать - это нормально
      const icons: Record<AtomType, string> = {
        [AtomType.MULTICONTENT]: '$playSolid',
        [AtomType.MULTILEVELTEST]: '$questionSquareSolid',
        [AtomType.QUIZ]: '$questionSquareSolid',
        [AtomType.SCORM]: '$playSolid',
        [AtomType.TRACK]: '', // Заглушка
        [AtomType.PROGRAM]: '', // Заглушка
        [AtomType.UNKNOWN]: '', // Заглушка
      };

      return icons[this.atomType] ?? '';
    },

    iconStyles(): {} {
      switch (this.atomType) {
        case AtomType.MULTICONTENT:
          return { marginLeft: '3px' };
        case AtomType.SCORM:
          return { marginLeft: '3px' };
        case AtomType.MULTILEVELTEST:
          return { marginLeft: '1px' };
        case AtomType.QUIZ:
          return { marginLeft: '1px' };
        default:
          return {};
      }
    },

    badgeColor(): string {
      return this.isCompleted
        ? 'tt-white'
        : '';
    },
  },
});

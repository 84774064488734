
import { defineComponent, PropType, computed } from 'vue';
import { useI18n } from '@/plugins/vue-i18n';
import { IAtomCount } from '@/components/course/contentTree/CourseItemsCounter/CourseItemsCounter.types';
import { AtomType } from '@/domains/atom';

export default defineComponent({
  name: 'CourseItemsCounter',
  props: {
    items: {
      type: Array as PropType<IAtomCount[]>,
      default: () => [] as IAtomCount[],
    },
  },
  setup(props) {
    const { tc } = useI18n();

    const lessons: number = props.items
      .filter((i) => i.type === AtomType.MULTICONTENT || i.type === AtomType.SCORM)
      .reduce((count, { value }) => count + value, 0);

    const tests: number = props.items
      .filter((i) => i.type === AtomType.MULTILEVELTEST || i.type === AtomType.QUIZ)
      .reduce((count, { value }) => count + value, 0);

    const counts = computed<string>(() => [
      lessons ? tc('pluralized.lessonsCount', lessons) : null,
      tests ? tc('pluralized.testsCount', tests) : null,
    ].filter(Boolean).join(', '));

    return {
      counts,
    };
  },
});

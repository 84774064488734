import { render, staticRenderFns } from "./MaterialTypeIcon.vue?vue&type=template&id=7ffc8ca0&scoped=true&"
import script from "./MaterialTypeIcon.vue?vue&type=script&lang=ts&"
export * from "./MaterialTypeIcon.vue?vue&type=script&lang=ts&"
import style0 from "./MaterialTypeIcon.vue?vue&type=style&index=0&id=7ffc8ca0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ffc8ca0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBadge,VIcon})


import { computed, defineComponent, PropType } from 'vue';

import TTPrompt from '@uikit/components/TTPrompt/TTPrompt.vue';
import CourseStep from '@/components/course/contentTree/CourseStep';
import CourseSubsection from '@/components/course/contentTree/CourseSubsection';
import CourseItemsCounter from '@/components/course/contentTree/CourseItemsCounter/CourseItemsCounter.vue';
import CourseDurationCounter from '@/components/course/contentTree/CourseDurationCounter/CourseDurationCounter.vue';
import AccordionSimple from '@/components/ui/AccordionSimple';
import CourseSectionProgress from '@/components/course/contentTree/CourseSectionProgress/CourseSectionProgress.vue';
import { UUID } from '@/domains/common';
import { AtomType } from '@/domains/atom';
import {
  ICourseAtomState, ICourseSectionState, ICourseStepState, isCourseStepAtom, isCourseStepSectionNode,
} from '@/domains/course';
import { ITree } from '@/common/tree';

export default defineComponent({
  name: 'CourseSection',
  components: {
    TTPrompt,
    CourseItemsCounter,
    CourseDurationCounter,
    CourseSubsection,
    CourseStep,
    AccordionSimple,
    CourseSectionProgress,
  },
  props: {
    section: {
      type: Object as PropType<ITree<ICourseSectionState, ICourseStepState>>,
      required: true,
    },
    sectionOrderNumber: {
      type: Number,
      default: 1,
    },
    stepTooltipText: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const items = computed(() => {
      const children = props.section.children as ITree<ICourseStepState>[];
      const sectionAtoms = children
        .filter((child) => {
          return child.current.itemType === 'step';
        });
      const subsectionsAtoms = children
        .filter((child) => child.current.itemType === 'section')
        .flatMap((child) => child.children);

      const allAtoms = sectionAtoms.concat(subsectionsAtoms) as ITree<ICourseAtomState>[];

      return allAtoms.map((atom) => ({
        type: atom.current.atomType,
        value: 1,
      }));
    });

    const durationMinutes = computed(() => {
      const hourToMinutes = props.section.current.durationHours * 60; // Note: переводим часы в минуты
      return hourToMinutes + props.section.current.durationMinutes;
    });

    const progress = computed(() => {
      const {
        finishedStepsCount,
        totalStepsCount,
      } = props.section.current;

      if (finishedStepsCount && totalStepsCount) {
        return Math.round((100 * finishedStepsCount) / totalStepsCount);
      }

      return 0;
    });

    const isSectionDisabled = computed(() => props.section?.current.isDisabled ?? false);

    const runStep = (data: { stepId: UUID, atomType: AtomType }) => {
      emit('course:step-run', data);
    };

    return {
      items,
      durationMinutes,
      progress,
      isSectionDisabled,
      runStep,
      isCourseStepAtom,
      isCourseStepSectionNode,
    };
  },
});

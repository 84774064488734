
import {
  computed, defineComponent, PropType, toRefs,
} from 'vue';

import CourseHeader from '@/components/course/CourseHeader/CourseHeader.vue';
import CourseSection from '@/components/course/contentTree/CourseSection';
import AtomHasNewVersionBanner from '@/components/catalogue/AtomHasNewVersionBanner/AtomHasNewVersionBanner.vue';
import CourseStep from '@/components/course/contentTree/CourseStep';

import IDeadlineChipInfo from './types';
import { IBreadcrumb, UUID } from '@/domains/common';
import { useI18n } from '@/plugins/vue-i18n';
import { Names } from '@/plugins/vue-router';
import { isEmpty, isNull } from '@/helpers/lodash';

import { AtomType, IAtom } from '@/domains/atom';
import { IAssignment } from '@/domains/assignment';
import { ICourseStepState, isCourseStepAtomNode, isCourseStepSectionNode } from '@/domains/course';
import { ITree } from '@/common/tree';

export default defineComponent({
  name: 'CoursePage',
  components: {
    CourseHeader,
    CourseSection,
    CourseStep,
    AtomHasNewVersionBanner,
  },
  props: {
    assignmentSessionId: {
      type: Number,
      default: 0,
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonDataLabel: {
      type: String,
      default: '',
    },
    course: {
      type: Object as PropType<IAtom>,
      required: true,
    },
    deadlineChipInfo: {
      type: Object as PropType<IDeadlineChipInfo>,
      default: () => ({}),
    },
    isSelfAssigned: {
      type: Boolean,
      default: false,
    },
    onGoTo: {
      type: Function,
      default: () => ({}),
    },
    actions: {
      type: Array as PropType<Record<string, string | (() => void)>[]>,
      default: () => [],
    },
    preparedCourse: {
      type: Array as PropType<ITree<ICourseStepState, ICourseStepState>[]>,
      default: () => [],
    },
    tooltipText: {
      type: String,
      default: '',
    },
    hasNewVersion: {
      type: Boolean,
      default: false,
    },
    assignmentUserId: {
      type: Number as PropType<IAssignment['assignmentUserId']>,
      default: NaN,
    },
  },

  setup(props, { emit }) {
    const { preparedCourse } = toRefs(props);
    const { t } = useI18n();

    const breadcrumbs = computed<IBreadcrumb[]>(() => {
      const items = [
        {
          text: t('CourseHeader.breadcrumbs.learning'),
          disabled: false,
          to: { name: Names.R_APP_LEARNING },
          'data-test-label': 'my-learning',
        },
        {
          text: props.course.name ?? '',
          disabled: true,
          'data-test-label': 'track-name',
        },
      ];

      if (!props.assignmentSessionId) {
        items.splice(1, 0, {
          text: t('CourseHeader.breadcrumbs.catalogue'),
          disabled: false,
          to: { name: Names.R_APP_LEARNING_CATALOGUE },
          'data-test-label': 'catalogue',
        });
      }

      return items;
    });

    const hasCourseSteps = computed(() => !isEmpty(preparedCourse.value));

    const isCoursePrepared = computed(() => !isNull(preparedCourse.value));

    const atomType = computed(() => AtomType);

    const runStep = (data: { stepId: UUID; atomType: AtomType }) => {
      emit('course:step-run', data);
    };

    return {
      breadcrumbs,
      hasCourseSteps,
      isCoursePrepared,
      atomType,
      runStep,
      isCourseStepAtomNode,
      isCourseStepSectionNode,
    };
  },
});


import {
  computed, defineComponent, PropType, toRefs,
} from 'vue';

import TTPrompt from '@uikit/components/TTPrompt/TTPrompt.vue';
import MaterialTypeIcon from '@/components/course/contentTree/MaterialTypeIcon.vue';

import { isEmpty } from '@/helpers';
import { ICourseAtomState } from '@/domains/course';

export default defineComponent({
  name: 'CourseStep',
  components: {
    MaterialTypeIcon,
    TTPrompt,
  },
  props: {
    atom: {
      type: Object as PropType<ICourseAtomState>,
      required: true,
    },
    tooltipText: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { atom } = toRefs(props);
    const hasAtom = computed(() => !isEmpty(props.atom));

    const isDisabled = computed<boolean>(() => {
      return atom.value?.isDisabled;
    });

    const isActive = computed<boolean>(() => {
      return atom.value?.isActive;
    });

    const isCompleted = computed<boolean>(() => {
      return atom.value?.isCompleted;
    });

    const isFailed = computed<boolean>(() => {
      return atom.value?.isFailed;
    });

    const isWasted = computed<boolean>(() => {
      return atom.value?.isWasted;
    });

    const runTrack = () => {
      if (!atom.value.isDisabled) {
        emit('course:step-run', { stepId: atom.value.id, atomType: atom.value.atomType });
      }
    };

    return {
      hasAtom,
      isActive,
      isDisabled,
      isCompleted,
      isFailed,
      isWasted,
      runTrack,
    };
  },
});

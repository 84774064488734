
import { defineComponent, PropType } from 'vue';
import { useDayJs } from '@/plugins/dayjs/composables';

export default defineComponent({
  name: 'CourseDurationCounter',
  props: {
    value: {
      type: Number as PropType<number>, // Note: минуты
      default: 0,
    },
    textColor: {
      type: String,
      default: 'tt-light-mono-100--text',
    },
  },
  setup() {
    const dayjs = useDayJs();

    function duration(v: number): string {
      const pure = dayjs.duration(v, 'minutes');
      const hours = Math.floor(pure.asHours()); // Часы округляем
      const minutes = pure.minutes(); // Минуты уже будут округлены в меньшую сторону
      return [
        hours > 0 ? `${hours} ч` : null,
        minutes > 0 ? `${minutes} мин` : null,
      ].filter(Boolean).join(' ');
    }
    return {
      duration,
    };
  },
});


import { defineComponent, PropType, ref } from 'vue';

import TTPrompt from '@uikit/components/TTPrompt/TTPrompt.vue';
import { AtomType } from '@/domains/atom';
import { UUID } from '@/domains/common';

import CourseStep from '@/components/course/contentTree/CourseStep';
import AccordionSimple from '@/components/ui/AccordionSimple';
import { ICourseSectionState, ICourseStepState, isCourseStepAtom } from '@/domains/course';
import { ITree } from '@/common/tree';

export default defineComponent({
  name: 'CourseSubsection',
  components: {
    TTPrompt,
    CourseStep,
    AccordionSimple,
  },
  props: {
    subsection: {
      type: Object as PropType<ITree<ICourseSectionState, ICourseStepState>>,
      required: true,
    },
    stepTooltipText: {
      type: String,
      default: '',
    },
  },
  setup(_, { emit }) {
    const isAccordionOpened = ref<boolean>(false);

    const toggle = () => {
      isAccordionOpened.value = !isAccordionOpened.value;
    };

    const runStep = (data: { stepId: UUID, atomType: AtomType }) => {
      emit('course:step-run', data);
    };

    return {
      isAccordionOpened,
      toggle,
      runStep,
      isCourseStepAtom,
    };
  },
});


import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AccordionSimple',
  props: {
    hideActions: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    headerClasses: {
      type: String,
      default: '',
    },
  },
});

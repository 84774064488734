
import {
  computed, defineComponent, PropType, ref, toRefs,
} from 'vue';

import TTPrompt from '@uikit/components/TTPrompt/TTPrompt.vue';
import BaseChip from '@/components/ui/BaseChip';
import ProvidersChip from '@/components/ui/ProvidersChip';
import ActionMenu from '@/components/ui/ActionMenu';

import { getTrackCover } from '@/domains/track';
import { ICourseHeaderProps } from './types';

export default defineComponent({
  name: 'CourseHeader',

  components: {
    BaseChip,
    ProvidersChip,
    ActionMenu,
    TTPrompt,
  },
  props: {
    buttonText: {
      type: String as PropType<ICourseHeaderProps['buttonText']>,
      required: true,
    },
    buttonDataLabel: {
      type: String as PropType<ICourseHeaderProps['buttonDataLabel']>,
      default: '',
    },
    course: {
      type: Object as PropType<ICourseHeaderProps['course']>,
      required: true,
    },
    deadlineChipInfo: {
      type: Object as PropType<ICourseHeaderProps['deadlineChipInfo']>,
      default: () => ({

        type: '',
        text: '',
      }),
    },
    isSelfAssigned: {
      type: Boolean as PropType<ICourseHeaderProps['isSelfAssigned']>,
      default: false,
    },
    onGoTo: {
      type: Function as PropType<ICourseHeaderProps['onGoTo']>,
      default: () => {},
    },
    actions: {
      type: Array as PropType<ICourseHeaderProps['actions']>,
      default: () => [],
    },
  },

  setup(props) {
    const { course } = toRefs(props);

    const coverUrl = computed<string>(() =>
      getTrackCover(course.value.id));

    const isImageLoading = ref(false);
    const onCoverLoadStartHandler = () => {
      isImageLoading.value = true;
    };
    const onCoverLoadErrorHandler = () => {
      isImageLoading.value = false;
    };

    const hasProviders = computed<boolean>(() => course.value.providers && course.value.providers.length > 0);

    const formattedDuration = computed<string>(() => {
      const hours = Math.floor((course.value.duration ?? 0) / 60);
      const minutes = Math.round((course.value.duration ?? 0) % 60);

      if (hours && minutes) {
        return `${hours} ч ${minutes} мин`;
      }

      if (hours && !minutes) {
        return `${hours} ч`;
      }

      return `${minutes} мин`;
    });

    const name = computed<string>(() => course.value.name ?? '');

    return {
      coverUrl,
      isImageLoading,
      onCoverLoadStartHandler,
      onCoverLoadErrorHandler,
      hasProviders,
      formattedDuration,
      name,
    };
  },
});
